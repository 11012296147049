<template>
  <div>
    <emulators-banner/>
    <div id="app">
      <div v-if="($store.account && $route.path != route('/login')) && !$route.meta.noNav">
        <div class="text-center">
          <b-button class="float-left mt-2 ml-2 burger-btn" @click="toggleSidebar" target="sidebar">
            <font-awesome-icon icon="bars" style="color:black" />
          </b-button>
          <div class="p-2" style="height:54px;">
            <img :srcset="$app.productLogoTitle + ' 2x'" />
            <b-badge v-if="$app.environment" variant="success">{{ $app.environment }}</b-badge>
          </div>
        </div>
        <b-sidebar id="sidebar" :visible="sidebarOpen" @hidden="sidebarOpen = false">
          <b-nav class="sidebar" vertical>
            <b-nav-item :to="route('/upload')"><font-awesome-icon icon="upload" /> Upload</b-nav-item>
            <b-nav-item :to="route('/list')"><font-awesome-icon icon="list" /> List</b-nav-item>
            <b-nav-item :to="route('/settings')"><font-awesome-icon icon="user-cog" /> Settings</b-nav-item>
            <div class="fixed-bottom m-2 sub-mt-2">
              <div class="form-inline sub-mr-2">
                <b-avatar :src="$store.account?.photoURL"></b-avatar>
                <div style="font-size: 1rem;">{{ $store.account?.email }}</div>
              </div>
              <div class="form-inline sub-mr-2">
                <b-avatar><font-awesome-icon icon="building" /></b-avatar>
                <div style="font-size: 1rem;">{{ $store.state?.teamInfo?.name || "No Team" }}</div>
              </div>
              <b-button @click="logout"><font-awesome-icon icon="sign-out" />Logout</b-button>
            </div>
          </b-nav>
        </b-sidebar>
      </div>
      <router-view v-if="$store.account || $route.path == route('/login') || isRouteOpen()"></router-view>
    </div>
    <my-footer v-if="!$route.meta.noFooter" />
  </div>
</template>
<script>
import { app, useEmulators } from "@/services/firebase";
import { getAuth } from "firebase/auth";
let auth = getAuth(app);
import { getLog } from "@/services/log";
let log = getLog("vid-router");
import { serverEpochMS } from "@/services/server-time";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOut, faList, faUpload, faBookmark, faSearch, faTimes, faEye, faExternalLinkAlt, faChartColumn, faThLarge, faLineChart, faFilePdf, faVideo, faBuilding, faExclamationTriangle, faCog, faUserCog, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
library.add(faSignOut, faList, faUpload, faBookmark, faSearch, faTimes, faEye, faExternalLinkAlt, faChartColumn, faThLarge, faLineChart, faFilePdf, faVideo, faBuilding, faExclamationTriangle, faCog, faUserCog, faGlobeAmericas);

export default {
  components: {
    MyFooter: () => import("@/components/Footer.vue"),
    EmulatorsBanner: () => import("@/components/emulatorsBanner.vue")
  },
  data() {
    return {
      useEmulators,
      sidebarOpen: false,
      root: "/vid",
      default: "/upload"
    };
  },
  mounted() {
    // Load debug
    if (this.$route.query.debug) {
      this.$debug.isAvailable = true;
      this.$debug.isOn = true;
      localStorage.setItem("debug", serverEpochMS());
    } else {
      this.$debug.isAvailable = localStorage.getItem("debug") != null;
    }
    // store
    this.$store.setState(this);
    // account
    this.AuthStateChangedListener = auth.onAuthStateChanged(async (account) => {
      log.log("onAuthStateChanged", account);
      //account = "forced";
      // No account shows login page
      if (!account && !this.isRouteOpen()) {
        if (this.$route.path != this.route("/login")) {
          this.$router.push(this.route("/login"));
        }
        await this.$store.setAccount(account);
      }
      else {
        // has account
        await this.$store.setAccount(account);
        if (!this.isRouteOpen() && !await this.$store.hasAccess("vid"))
          this.$router.push(("/no-access"));
        if (account && this.$route.path == this.route("")) {
          this.$router.push(this.route(this.default));
        }
      }
      this.$forceUpdate();
    }, (error) => {
      log.error("onAuthStateChanged", error);
    }, () => {
      log.log("onAuthStateChanged completed");
    });
  },
  methods: {
    isRouteOpen() {
      return this.$route.path.startsWith(this.route("/watch/"))
    },
    route(path) {
      return this.root + path;
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    logout() {
      // Perform logout logic here
      auth.signOut()
        .then(() => {
          log.log("User signed out");
        })
        .catch(error => {
          log.error("Error signing out:", error);
        });
    }
  }
};
</script>
<style scoped>
.burger-btn {
  /* Add burger button styles here */
  background-color: white;
}

.sidebar {
  font-size: 20pt;
}

.sidebar>b-nav-item>a:hover {
  background-color: #b5b6b6;
}
</style>
  
  
  